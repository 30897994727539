























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseSelect from '@improve/common-components/src/components/core/BaseSelect.vue';
import Ticket from '@improve/common-utils/src/model/Ticket';
import User from '@improve/common-utils/src/model/User';
import TicketSearchParams from '@improve/common-utils/src/types/TicketSearchParams';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import TicketStats from '@improve/common-utils/src/types/TicketStats';
import TicketStatsParams from '@improve/common-utils/src/types/TicketStatsParams';
import BaseTicketCard from '../../ticket/BaseTicketCard.vue';

@Component({
  name: 'MyImprovesTab',
  components: {
    BaseTicketCard,
    BaseSelect
  }
})
export default class MyImprovesTab extends Vue {
  @Action getAllTickets!: ActionMethod;

  @Action fetchTransitions!: ActionMethod;

  @Action getTicketStats!: ActionMethod;

  @Getter ticketStats!: TicketStats;

  @Getter activeWorkflowStatuses!: Array<string>;

  @Getter currentUser?: User;

  options: Array<MenuOption> = [
    {
      title: this.$t('menu.allStates').toString(),
      value: 'all'
    }
  ];

  ticketFilter = new TicketSearchParams();

  statsFilter = new TicketStatsParams();

  selectedStatus = 'all';

  userTickets = new Array<Ticket>();

  async created(): Promise<void> {
    await Promise.all([this.fetchStatsForUser(), this.fetchTransitions()]);
    await this.setInitialStatus();
  }

  async fetchStatsForUser(): Promise<void> {
    this.statsFilter.creator = [this.currentUser!.id!];
    await this.getTicketStats(this.statsFilter);
  }

  async setInitialStatus(): Promise<void> {
    // Set status filter if available from url
    if (this.$route.query.status) {
      this.selectedStatus = this.$route.query.status!.toString();
      this.ticketFilter.status = this.selectedStatus === 'all' ? this.activeWorkflowStatuses : [this.selectedStatus];
    } else {
      this.ticketFilter.status = this.activeWorkflowStatuses;
    }

    this.ticketFilter.creator = [this.currentUser!.id!];
    this.userTickets = await this.getAllTickets(this.ticketFilter);
    this.setFilterOptions();
  }

  setFilterOptions(): void {
    this.activeWorkflowStatuses.forEach((status: string) => {
      const numImproves = this.ticketStats.byStatus.get(status) || 0;
      let tLC = this.$t(`ticket.status.${status}`).toString().toLowerCase();
      tLC = `${tLC.charAt(0).toUpperCase()}${tLC.substring(1, tLC.length)}`;
      this.options.push({
        title: tLC,
        value: status,
        disabled: !numImproves
      });
    });
  }

  async setStatusFilter(status: MenuOption): Promise<void> {
    this.ticketFilter.status = status.value === 'all' ? [] : [status.value!];
    this.userTickets = await this.getAllTickets(this.ticketFilter);
    this.$router.push({ query: { status: status.value } });
  }
}
